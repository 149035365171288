import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as Yup from 'yup'
import { PageLayout } from '../components/PageLayout'
import { SolicitudReposicionMaterialObservaciones } from '../components/SolicitudReposicionMaterialObservaciones'
import { TitlePage } from '../components/TitlePage'

export const SolicitudReposicionMaterial = () => {
  const [solicitud, setSolicitud] = useState(null)
  const [materialSeleccionable, setMaterialSeleccionable] = useState(null)
  const [materialSolicitar, setMaterialSolicitar] = useState([])
  const [showSuccess, setShowSuccess] = useState(false)

  const {
    inVehiculoId,
    inIndicativoServicioId,
    inConductorId,
    inCamilleroId
  } = useSelector(({ loginReducer }) => loginReducer)

  const fetchMaterialSeleccionable = async () => {
    const response = await fetch(`${process.env.REACT_APP_APIVEL}A_SOLICITUD_MATERIAL_CARGAR_SOLICITABLE.pro?IN_VEHICULO_ID=${inVehiculoId}`)
    const result = await response.json()
    setMaterialSeleccionable(result)
  }

  const fetchSolicitud = async () => {
    let params = `IN_VEHICULO_ID=${inVehiculoId}`
    params = `${params}&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
    params = `${params}&IN_CONDUCTOR_ID=${inConductorId}`
    params = `${params}&IN_CAMILLERO_ID=${inCamilleroId}`
    const response = await fetch(`${process.env.REACT_APP_APIVEL}A_SOLICITUD_MATERIAL_CREAR.pro?${params}`)
    const result = await response.json()
    setSolicitud(result)
  }
  console.log(solicitud)
  const addMaterial = async () => {
    const material = materialSolicitar.map(e => e)
    for (let index = 0; index < material.length; index++) {
      const element = material[index];
      let params = `IN_SOLICITUD_MATERIAL_ID=${solicitud.SOLICITUD_MATERIAL_ID}`
      params = `${params}&IN_CONCEPTO_SOLICITABLE_ID=${element.conceptoId}`
      params = `${params}&IN_DESCRIPCION_OTROS=${element.conceptoId !== '0' ? '' : element.concepto}`
      params = `${params}&IN_CANTIDAD=${element.cantidad}`
      params = `${params}&IN_CONCEPTO_OBSERVACIONES=${element.observaciones}`
      const response = await fetch(`${process.env.REACT_APP_APIVEL}A_SOLICITUD_MATERIAL_ADD_MATERIAL.pro?${params}`)
      const result = await response.json()
    }
    setSolicitud(null)
    setMaterialSolicitar([])
    setShowSuccess(true)
  }

  useEffect(() => {
    if (!materialSeleccionable) {
      fetchMaterialSeleccionable()
    }
  }, [materialSeleccionable])

  useEffect(() => {
    if (!solicitud) {
      fetchSolicitud()
    }
  }, [solicitud])

  const formik = useFormik({
    initialValues: {
      tipoMaterial: '',
      materialSeleccionado: '',
      otroMaterial: '',
      cantidad: 0,
      observaciones: ''
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      materialSolicitar.length > 0 && addMaterial()
    },
  })

  const addMaterialSolicitar = () => {
    if ((formik.values.materialSeleccionado !== '' || formik.values.otroMaterial !== '') && formik.values.cantidad > 0) {
      const concepto = formik.values.tipoMaterial === '0' ? formik.values.otroMaterial : materialSeleccionable.CONCEPTOS_SOLICITABLES_LISTA.filter(e => e.CONCEPTO_SOLICITABLE_ID === formik.values.materialSeleccionado)[0].CONCEPTO_SOLICITABLE_NOMBRE
      const conceptoId = formik.values.tipoMaterial === '0' ? '0' : formik.values.materialSeleccionado
      setMaterialSolicitar([...materialSolicitar, { cantidad: formik.values.cantidad, conceptoId: conceptoId, concepto: concepto.toUpperCase(), estado: 'PENDIENTE', observaciones: formik.values.observaciones }])
      formik.setFieldValue('otroMaterial', '')
      formik.setFieldValue('materialSeleccionado', '')
      formik.setFieldValue('cantidad', 0)
      formik.setFieldValue('observaciones', '')
    }
  }

  const deleteMaterialSolicitar = (e) => {
    const id = e.currentTarget.id
    const temp = materialSolicitar.filter((e, index) => index !== Number(id))
    setMaterialSolicitar(temp)
  }

  return (
    <>
      {
        showSuccess
        &&
        <div id="spinner-container" className='d-flex justify-content-center align-items-center'>
          <div className="alert alert-success" role="alert">
            SOLICITUD ENVIADA CORRECTAMENTE
            <NavLink to='/solicitudes-reposicion-material' className='btn btn-primary ms-3'>ACEPTAR</NavLink>
          </div>
        </div>
      }
      <PageLayout>
        <div className='content d-flex flex-column'>
          <TitlePage title='SOLICITUD DE REPOSICIÓN DE MATERIAL' />
          <div className='mb-3 px-3'>
            <form onSubmit={formik.handleSubmit} id='solicitudReposicionMaterial'>

              <div className='row mb-3'>
                <div className='d-flex'>
                  {
                    solicitud &&
                    <div className='me-auto'>{`CREADA: ${solicitud.SOLICITUD_FECHA_HORA}`}</div>
                  }
                  {
                    solicitud &&
                    <div className={`${solicitud.SOLICITUD_CERRADA === '0' ? 'text-success' : 'text-black-50'}`}>{`${solicitud.SOLICITUD_CERRADA === '0' ? 'ABIERTA' : 'CERRADA'}`}</div>
                  }
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col'>
                  <label htmlFor='tipoMaterial' className='form-label'> Tipo de material</label>
                  <select
                    className={`form-select ${(formik.touched.tipoMaterial && formik.errors.tipoMaterial) && 'form-control-error'}`}
                    id='tipoMaterial'
                    name='tipoMaterial'
                    value={formik.values.tipoMaterial}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value=''>Seleccione el tipo de material...</option>
                    {
                      materialSeleccionable && materialSeleccionable.CONCEPTOS_TIPOS_LISTA.map(material => {
                        return (
                          <option key={material.CONCEPTO_SOLICITABLE_TIPO_ID} value={material.CONCEPTO_SOLICITABLE_TIPO_ID}>{material.CONCEPTO_SOLICITABLE_TIPO_NOMBRE}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>

              <div className='row'>
                <div className='col-10'>
                  {
                    (!formik.values.tipoMaterial || Number(formik.values.tipoMaterial) > 0) &&
                    <>
                      <label htmlFor='materialSeleccionado' className='form-label'> Material</label>
                      <select
                        className={`form-select ${(formik.touched.materialSeleccionado && formik.errors.materialSeleccionado) && 'form-control-error'}`}
                        id='materialSeleccionado'
                        name='materialSeleccionado'
                        value={formik.values.materialSeleccionado}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value=''>Seleccione material...</option>
                        {
                          formik.values.tipoMaterial && materialSeleccionable.CONCEPTOS_SOLICITABLES_LISTA.map(material => {
                            return (
                              (material.CONCEPTO_SOLICITABLE_TIPO_ID === formik.values.tipoMaterial)
                              &&
                              <option key={material.CONCEPTO_SOLICITABLE_ID} value={material.CONCEPTO_SOLICITABLE_ID}>{material.CONCEPTO_SOLICITABLE_NOMBRE}</option>
                            )
                          })
                        }
                      </select>
                    </>
                  }
                  {
                    (formik.values.tipoMaterial && formik.values.tipoMaterial === '0') &&
                    <>
                      <label htmlFor='otroMaterial' className='form-label'>Material</label>
                      <input
                        className={`form-control form-control-sm ${(formik.touched.otroMaterial && formik.errors.otroMaterial) && 'form-control-error'}`}
                        type='text'
                        name='otroMaterial'
                        id='otroMaterial'
                        value={formik.values.otroMaterial}
                        onChange={formik.handleChange}
                      />
                    </>
                  }
                </div>
                <div className='col-2 mb-3'>
                  <label htmlFor='cantidad' className='form-label'>Cantidad</label>
                  <input
                    className={`form-control form-control ${(formik.touched.cantidad && formik.errors.cantidad) && 'form-control-error'}`}
                    type='number'
                    name='cantidad'
                    id='cantidad'
                    min={0}
                    value={formik.values.cantidad}
                    onChange={formik.handleChange}
                  />
                </div>

              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <label htmlFor='observaciones' className='form-label'>Observaciones</label>
                  <input
                    className={`form-control form-control-sm`}
                    type='text'
                    name='observaciones'
                    id='observaciones'
                    value={formik.values.observaciones}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className='col text-end mb-3'>
                <button type='button' className='btn btn-primary' onClick={addMaterialSolicitar}>AÑADIR A LA SOLICITUD</button>
              </div>

              <div className='row mb-3'>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">CANT</th>
                      <th scope="col">MATERIAL</th>
                      <th scope="col">ESTADO</th>
                      <th scope="col">OBSERVACIONES</th>
                      <th className='text-end' scope="col">
                        <FaRegTrashAlt />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (solicitud && solicitud.CONCEPTOS_SOLICITADOS_LISTA) && solicitud.CONCEPTOS_SOLICITADOS_LISTA.map((element) => {
                        return (
                          <tr key={crypto.randomUUID()}>
                            <td>{element.CONCEPTO_SOLICITADO_CANTIDAD}</td>
                            <td>{element.CONCEPTO_SOLICITADO_NOMBRE}</td>
                            <td >SOLICITADO</td>
                            <td><SolicitudReposicionMaterialObservaciones data={element.CONCEPTO_SOLICITADO_OBERVACIONES} /></td>
                            <td className='text-end' scope="col">
                              <button type="button" className='btn py-0 px-0'><FaRegTrashAlt className='text-black-50' /></button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    {
                      materialSolicitar.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td>{element.cantidad}</td>
                            <td>{element.concepto}</td>
                            <td>{element.estado}</td>
                            <td><SolicitudReposicionMaterialObservaciones data={element.observaciones} /></td>
                            <td className='text-end' scope="col">
                              <button type="button" className='btn py-0 px-0'><FaRegTrashAlt onClick={deleteMaterialSolicitar} id={index} /></button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>

            </form >

          </div>
          <div className='text-end mt-auto pt-3 px-3'>
            <NavLink to='/solicitudes-reposicion-material' className='btn btn-primary me-1'>VOLVER</NavLink>
            <button type='submit' form='solicitudReposicionMaterial' className='btn btn-primary' disabled={materialSolicitar.length === 0}>ENVIAR SOLICITUD</button>
          </div>
        </div >
      </PageLayout >
    </>
  )
}