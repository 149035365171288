import React, { useState, useEffect } from 'react'
import 'animate.css'

export const CitaTAllerNuevoTrabajoModal = ({ resolve, reject, msg, acceptBtn, cancelBtn = '' }) => {
  const [observaciones, setObservaciones] = useState('')

  const [trabajosPredefinidos, setTrabajosPredefinidos] = useState(null)
  const [trabajoPredefinido, setTrabajoPredefinido] = useState('0')

  const [tiposTrabajosPredefinidos, setTiposTrabajosPredefinidos] = useState(null)
  const [tipoTrabajoPredefinido, setTipoTrabajoPredefinido] = useState('0')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)


  const getTrabajosPredefinidos = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_APIVEL}A_CITA_TALLER_LISTA_TRABAJOS_PREDEFINIDOS.PRO`)
      const result = await response.json()
      setTiposTrabajosPredefinidos(result.TRABAJOS_PREDEFINIDOS_TIPOS_LISTA)
      setTrabajosPredefinidos(result.TRABAJOS_PREDEFINIDOS_LISTA)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!trabajosPredefinidos) {
      getTrabajosPredefinidos()
    }
  }, [trabajosPredefinidos])

  const accept = () => {
    (trabajoPredefinido !== '0' || observaciones !== '')
      ?
      resolve({ trabajoPredefinidoId: trabajoPredefinido, descripcionTrabajo: observaciones })
      :
      resolve('')
    closeModal()
  }

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  const handleChange = (e) => {
    setObservaciones(e.target.value)
  }

  const handleChangeTipoTrabajoPredefinido = (e) => {
    setTipoTrabajoPredefinido(e.target.value)
    setTrabajoPredefinido('0')
  }


  const handleChangeTrabajoPredefinido = (e) => {
    setTrabajoPredefinido(e.target.value)
  }

  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card animate__animated animate__fadeIn'>
        <div className='card-body'>

          <div className='row mb-3'>
            <div className='col'>
              <label htmlFor='tiposTrabajosPredefinidos' className='form-label'>Tipo de trabajo</label>
              <select
                className="form-select"
                id='tiposTrabajosPredefinidos'
                name='tiposTrabajosPredefinidos'
                value={tipoTrabajoPredefinido}
                onChange={handleChangeTipoTrabajoPredefinido}
              >
                <option value='0'>Seleccione un tipo de trabajo...</option>
                {
                  tiposTrabajosPredefinidos && tiposTrabajosPredefinidos.map(element => {
                    return (
                      <option key={element.TRABAJO_PREDEFINIDO_TIPO_ID} value={element.TRABAJO_PREDEFINIDO_TIPO_ID}>{element.TRABAJO_PREDEFINIDO_TIPO_NOMBRE}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <label htmlFor='trabajosPredefinidos' className='form-label'>Trabajo</label>
              <select
                className="form-select"
                id='trabajosPredefinidos'
                name='trabajosPredefinidos'
                value={trabajoPredefinido}
                onChange={handleChangeTrabajoPredefinido}
              >
                <option value='0'>Seleccione un trabajo...</option>
                {
                  trabajosPredefinidos && trabajosPredefinidos.filter((ele) => { return ele.TRABAJO_PREDEFINIDO_TIPO_ID === tipoTrabajoPredefinido }).map(element => {
                    return (
                      <option key={element.TRABAJO_PREDEFINIDO_ID} value={element.TRABAJO_PREDEFINIDO_ID}>{element.TRABAJO_PREDEFINIDO_NOMBRE}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>

          <label htmlFor='textoNuevoTrabajo'>Observaciones</label>
          <div className="form-floating">
            <textarea
              className="form-control"
              id="textNuevoTrabajo"
              onChange={handleChange}
            >
            </textarea>
          </div>
          <div className='text-end mt-3'>
            <button className='btn btn-primary me-1' onClick={cancel}>CANCELAR</button>
            <button className='btn btn-primary' onClick={accept}>ACEPTAR</button>
          </div>
        </div>
      </div>
    </div >
  )
}