import React from 'react'
import { AlertaListaSinResultados } from './AlertaListaSinResultados'
import { SolicitudesReposicionMaterialItem } from './SolicitudesReposicionMaterialItem'
import { Spinner } from './Spinner'

export const SolicitudesReposicionMaterialLista = ({ data }) => {
  return (
    < div className='mb-3 px-3'>
      {!data && <Spinner />}
      {
        data && data.length === 0
          ?
          <AlertaListaSinResultados />
          :
          (data && data.length > 0)
          &&
          data.map(element => {
            return (
              <SolicitudesReposicionMaterialItem key={element.SOLICITUD_ID} element={element} />
            )
          })
      }
    </div>
  )
}