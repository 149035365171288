import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { NavLink } from 'react-router-dom'

export const Registros = () => {
  return (
    <PageLayout>
      <div className='content'>
        <TitlePage title='REGISTROS' />
        <div className='mt-3 px-3'>
          <div className='row'>
            <div className="col-6">
              <div className='d-grid gap-2'>
                <NavLink to='/incidencia-general' className='btn btn-primary pt-3 pb-3 disabled'>INCIDENCIA GENERAL</NavLink>
                <NavLink to='/informe-de-golpe' className='btn btn-primary pt-3 pb-3' type="button">INFORME DE GOLPE</NavLink>
              </div>
            </div>
            <div className="col-6">
              <div className='d-grid gap-2'>
                <button className='btn btn-primary pt-3 pb-3 disabled' type="button">INSPECCIÓN TÉCNIA VEHICULAR (ITV)</button>
                <button className='btn btn-primary pt-3 pb-3 disabled' type="button">REGISTRO DE CALIDAD</button>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <div className="d-grid gap-2">
                <NavLink to="/solicitud-cambio-turno" className="btn btn-primary pt-3 pb-3">SOLICITUD DE CAMBIO DE TURNO</NavLink>
              </div>
            </div>
            <div className="col-6">
              <div className="d-grid gap-2">
                <NavLink to="/valoracion-mentor" className="btn btn-primary pt-3 pb-3">EVALUACIÓN MENTOR</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}