import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { SideBar } from '../components/SideBar'
import { CalidadRegistros } from '../pages/CalidadRegistros'
import { CalidadRegistroVer } from '../pages/CalidadRegistroVer'
import { CitasTaller } from '../pages/CitasTaller'
import { CitaTallerVer } from '../pages/CitaTallerVer'
import { Contactos } from '../pages/Contactos'
import { Home } from '../pages/Home'
import { IncidenciaGeneral } from '../pages/IncidenciaGeneral'
import { InformeDeGolpe } from '../pages/InformeDeGolpe'
import { NotFound } from '../pages/NotFound'
import { OxigenoDevolver } from '../pages/OxigenoDevolver'
import { OxigenoRecoger } from '../pages/OxigenoRecoger'
import { Registros } from '../pages/Registros'
import { RepostajeModificar } from '../pages/RepostajeModificar'
import { RepostajeNuevo } from '../pages/RepostajeNuevo'
import { Repostajes } from '../pages/Repostajes'
import { ServicioGenericoRegistroAtencion } from '../pages/ServicioGenericoRegistroAtencion'
import { ServicioGenericoRegistrosAtenciones } from '../pages/ServicioGenericoRegistrosAtenciones'
import { Servicios } from '../pages/Servicios'
import { ServiciosActuales } from '../pages/ServiciosActuales'
import { ServiciosGenericos } from '../pages/ServiciosGenericos'
import { ServicioVer } from '../pages/ServicioVer'
import { SolicituCambioTurno } from '../pages/SolicituCambioTurno'
import { StockMaterial } from '../pages/StockMaterial'
import { StockMaterialReponer } from '../pages/StockMaterialReponer'
import { VehiculoCambio } from '../pages/VehiculoCambio'
import { VehiculoInterCambio } from '../pages/VehiculoInterCambio'
import { SolicitudesReposicionMaterial } from '../pages/SolicitudesReposicionMaterial'
import { SolicitudReposicionMaterial } from '../pages/SolicitudReposicionMaterial'
import { EvaluacionMentor } from '../pages/EvaluacionMentor'

export const DashBoardRoutes = () => {
  const { isOpenSidebar } = useSelector(({ sidebarReducer }) => sidebarReducer)
  return (
    <>
      {
        isOpenSidebar && <SideBar />
      }
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/servicios' element={<Servicios />} />
        <Route path='/servicios-actuales/:idServicioActivo' element={<ServiciosActuales />} />
        <Route path='/servicios-genericos' element={<ServiciosGenericos />} />
        <Route path='/ver-servicio/:servicioId/:tabla/:anio' element={<ServicioVer />} />
        <Route path='/contactos' element={<Contactos />} />
        <Route path='/repostajes' element={<Repostajes />} />
        <Route path='/nuevo-repostaje' element={<RepostajeNuevo />} />
        <Route path='/repostaje/:vehiculoId/:repostajeId' element={<RepostajeModificar />} />
        <Route path='/cambio-vehiculo' element={<VehiculoCambio />} />
        <Route path='/intercambio-vehiculo' element={<VehiculoInterCambio />} />
        <Route path='/recoger-oxigeno' element={<OxigenoRecoger />} />
        <Route path='/devolver-oxigeno' element={<OxigenoDevolver />} />
        <Route path='/material-vehiculo' element={<StockMaterial />} />
        <Route path='/reponer-material-vehiculo' element={<StockMaterialReponer />} />
        <Route path='/citas-taller' element={<CitasTaller />} />
        <Route path='/ver-cita-taller/:citaTallerId' element={<CitaTallerVer />} />
        <Route path='/registros-calidad' element={<CalidadRegistros />} />
        <Route path='/registro-calidad/:registroCalidadId' element={<CalidadRegistroVer />} />
        <Route path='/registros' element={<Registros />} />
        <Route path='/incidencia-general' element={<IncidenciaGeneral />} />
        <Route path='/informe-de-golpe' element={<InformeDeGolpe />} />
        <Route path='/solicitud-cambio-turno' element={<SolicituCambioTurno />} />
        <Route path='/servicio-generico-registros-atenciones/:servicioId/:servicioAnio/:servicioTabla' element={<ServicioGenericoRegistrosAtenciones />} />
        <Route path='/servicio-generico-nuevo-registro-atencion/:servicioId/:servicioAnio/:servicioTabla' element={<ServicioGenericoRegistroAtencion />} />
        <Route path='/servicio-generico-modificar-registro-atencion/:servicioId/:servicioAnio/:servicioTabla/:atencionId' element={<ServicioGenericoRegistroAtencion />} />
        <Route path='/solicitudes-reposicion-material' element={<SolicitudesReposicionMaterial />} />
        <Route path='/solicitud-reposicion-material' element={<SolicitudReposicionMaterial />} />
        <Route path='/valoracion-mentor' element={<EvaluacionMentor />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  )
}