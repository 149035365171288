export const EvaluacionMentorConceptos = ({ data, setData }) => {
  const valorar = (e) => {
    const val = e.target.value
    const pos = e.target.name
    const listaConceptos = [].concat(data.LISTA_CONCEPTOS)
    listaConceptos[pos].CONCEPTO_VALORACION = val
    setData({ ...data, LISTA_CONCEPTOS: listaConceptos })
  }

  return (
    <>
      {
        data.LISTA_CONCEPTOS.map((element, index) => {
          return (
            <div className="card my-2" key={crypto.randomUUID()}>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {element.CONCEPTO_DESC}
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="p-2">
                      <input className="form-check-input" type="radio" name={index} id={`concepto-valoracion-${index}-si`} value="1" onChange={valorar} checked={element.CONCEPTO_VALORACION === '1'} />
                      <label className="form-check-label ms-1" htmlFor="exampleRadios1">
                        SI
                      </label>
                    </div>
                    <div className="p-2">
                      <input className="form-check-input" type="radio" name={index} id={`concepto-valoracion-${index}-no`} value="0" onChange={valorar} checked={element.CONCEPTO_VALORACION === '0'} />
                      <label className="form-check-label ms-1" htmlFor="exampleRadios1">
                        NO
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </>
  )
}
