import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { SolicitudesReposicionMaterialLista } from '../components/SolicitudesReposicionMaterialLista'
import { TitlePage } from '../components/TitlePage'

export const SolicitudesReposicionMaterial = () => {
  const [data, setData] = useState(null)

  const { inVehiculoId } = useSelector(({ loginReducer }) => loginReducer)

  const fetchData = async () => {
    const resp = await fetch(`${process.env.REACT_APP_APIVEL}A_SOLICITUD_MATERIAL_ULTIMAS_SOLICITUDES.pro?IN_VEHICULO_ID=${inVehiculoId}`)
    const result = await resp.json()
    if (result === 0) {
      setData([])
    } else {
      const listaSolicitudes = result.SOLICITUDES_LISTA_SOLICITUDES.map(element => {
        const materiales = result.SOLICITUDES_LISTA_MATERIALES.filter(ele => ele.SOLICITUD_ID === element.SOLICITUD_ID)
        element.LISTA_MATERIALES = materiales
        return element
      })
      setData(listaSolicitudes)
    }
  }

  useEffect(() => {
    if (!data) {
      fetchData()
    }
  }, [data])

  return (
    <PageLayout>
      <div className='content'>
        <TitlePage title='SOLICITUDES DE REPOSICIÓN DE MATERIAL' />
        <div className='text-end mb-3 px-3'>
          <NavLink to='/solicitud-reposicion-material' className='btn btn-primary me-1'>NUEVA SOLICITUD</NavLink>
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
        </div>
        <SolicitudesReposicionMaterialLista data={data} />
      </div>
    </PageLayout>
  )
}