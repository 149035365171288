import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

export const SolicitudesReposicionMaterialItem = ({ element }) => {
  const [showBody, setShowBody] = useState(false)

  const mostrarListaMaterial = () => {
    setShowBody(!showBody)
  }

  return (
    <>
      <div className='card mt-3'>
        <div className='card-header'>
          <div className='d-flex fw-bold'>
            <div className='me-auto'>{element.SOLICITUD_FECHA}</div>
            <div>{`${element.SOLICITUD_INDICATIVO_SERVICIO_NOMBRE}`}</div>
          </div>
        </div>
        <div className={`card-body py-4 text-center ${!showBody && 'no-show'}`}>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col" className='text-start'>MATERIAL ENTREGADO</th>
              </tr>
            </thead>
            <tbody>
              {
                element.LISTA_MATERIALES.map((element, index) => {
                  return (
                    <tr key={index}>
                      <td scope='col' className='text-start'>{`${element.SOLICITUD_CANTIDAD_ENTREGADA} - ${element.SOLICITUD_MATERIAL_CONCEPTO}`}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <div className='card-footer'>
          <div className='d-flex'>
            <div className={`me-auto ${element.SOLICITUD_CERRADA === '0' ? 'text-success' : 'text-black-50'}`}>{`${element.SOLICITUD_CERRADA === '0' ? 'ABIERTA' : 'CERRADA'}`}</div>
            <div>
              {
                element.SOLICITUD_CERRADA === '0'
                  ?
                  <NavLink to={`/solicitud-reposicion-material`} className='btn btn-primary'>
                    MODIFICAR SOLICITUD
                  </NavLink>
                  :
                  <button className='btn btn-primary' onClick={mostrarListaMaterial}>{showBody ? 'OCULTAR MATERIAL' : 'VER MATERIAL'}</button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}